
class GrapeUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
	}

	afterInit()
	{
		const el = document.getElementsByClassName('ps-navbar-container')[0];
		ko.applyBindings({}, el);
	}

	beforePageLoad()
	{
		// Show loader
	}

	afterPageLoad()
	{
		// Hide loader
	}
}

export default GrapeUIPlugin;
